.detailsBar {
    position: fixed;
    bottom: 0;
    right: 2%;
    min-width: 200px;
    z-index: 99;
}

@media only screen and (max-width: 450px) {
    .detailsBar {
        max-width: calc(100% - 65px);
    }

    .MuiPaper-root.MuiCard-root.makeStyles-card-328.MuiPaper-elevation1.MuiPaper-rounded {
        max-width: 100%;
        min-width: unset;
    }
}