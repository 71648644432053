.rbc-allday-cell {
    display: none;
}

.rbc-event {
    width: 100% !important;
    /* overflow: unset !important; */
    /* display: flex !important;
    flex-direction: row !important; */
}

.rbc-event.spaBooking { 
    background-color: #fe4c4c;
    left: 5% !important;
    z-index: 3;
    font-size: 11px;

}

.rbc-event.schedule { 
    background-color: #ffd9b3;
    color: black; 
    left: 0 !important; 
    z-index: 1;
}

.rbc-event.classBooking { 
    background-color: #FFA54C; 
    left: 5% !important; 
    font-size: 11px;
    z-index: 3;
}

.rbc-event.break { 
    background-color: #d9f0ba;
    left: 0 !important; 
    color: black;
    z-index: 2;
}

.rbc-event.clean { 
    background-color: #797979; 
    left: 5% !important; 
    z-index: 2;
}
.cleantimeAlignmentFix .rbc-event-label,
.cleantimeAlignmentFix .rbc-event-content .customEvent strong{
   position: absolute;
   bottom: 0;
}
.cleantimeAlignmentFix .rbc-event-label{
    left: 1px;
    font-weight: bold;
}
.cleantimeAlignmentFix .rbc-event-content .customEvent strong{
    right: 2px;
    padding-bottom: 1px;
}
.cleantimeAlignmentFix{
    /* background: red; */
    background-color: #797979; 
    left: 5% !important; 
    flex-direction: row-reverse;
    font-size: 10px;
    z-index: 2;
}
.rbc-event.clean .rbc-event-content .customEvent{
    font-size: 12px;
    font-weight: 200;
}
.popup {
    background-color: white;
    position: relative;
    padding: 1px 15px;
    color: black;
    border-radius: 10px;
    border: 1px solid black;
    width: 240px;
}

.popup::after {
    content: '';
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    top: 25%;
    left: -5px;
    border: 1px solid transparent;
    border-left-color: black;
    border-bottom-color: black;
}

.customEvent {
    height: 100%;
}

.popupContainer {
    position: absolute;
    left: 108%;
    z-index: 99;
    top: 25%;
}

.popupBody {
    line-height: 1px;
}

.filterContainer {
    display: flex;
}

.filterContainer .MuiFormGroup-root.MuiFormGroup-row {
    padding-left: 20px;
}

.customAgenda .agendaBody table {
    width: 100%;
    border: 1px solid black;
}

.customAgenda .agendaBody th, .customAgenda .agendaBody td {
    border-right: 1px solid black;
}

.rbc-time-view {
    min-width: 950px;
}

.noScheduleText {
    margin: auto;
    margin-top: 30px;
}

@media only screen and (min-width: 401px) {
    .MuiContainer-root.makeStyles-container-7.MuiContainer-maxWidthLg {
        min-width: 1000px;
    }
}
@media only screen and (max-width: 1200px) {
    .cleantimeAlignmentFix .rbc-event-label{
        left: auto;
     
    }
    .cleantimeAlignmentFix .rbc-event-content .customEvent strong{
       display: none
    }
}
@media only screen and (max-width: 725px) {
    .filterContainer {
        flex-direction: column;
    }
}

@media only screen and (max-width: 480px) {
    .MuiContainer-root.makeStyles-container-7.MuiContainer-maxWidthLg {
        min-width: 450px;
    }
    .rbc-toolbar {
        flex-direction: column;
        /* position: fixed; */
    }

    /* .customAgenda, .rbc-time-view {
        margin-top: 100px;
    } */
}